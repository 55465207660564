
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { BtSelectGuestsComponent } from '../select-guests/select-guests.component';
import { BtEvent } from '../../../services/event.service';


@Component({
  selector: 'btlib-cart-add-item',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './cart-add-item.component.html',
  styleUrl: './cart-add-item.component.scss'
})
export class BtCartAddItemComponent {
  bas = inject(BooktechAppService);

  @Input() opts:any = { };
  @Output() change = new EventEmitter<any>();

  @Input() pwp:any = undefined;
  @Input() pw:any = undefined;

  constructor() {


  }

  ngOnInit(): void {
    this.updateAmount();
    this.pw.checked = this.pw.quantity > 0;
    if(this.bas.envtest) console.log("AppCartAddItemComponent: ", this.pw);
  }



  onCheckbox(checked:Event) {
    
    
    let gcIdx = 0;
    let findCount = 1;
    let pw = this.pw;
    let pwp = this.pwp;
    if (pwp && !pwp.productObj.groupProduct) findCount = pwp.findCount;
    if (pw.quantitySelected) findCount = pw.quantitySelected;
    
    let gcVal = checked ? findCount : 0;

    if (!checked) {
      pw.guestCounts.map = { 0 : 0 };
    } else if (pw.copyGuestCounts && pwp) {
      pw.guestCounts.map = pwp.guestCounts.map;
    } else {
      pw.guestCounts.map[gcIdx] = gcVal;
    }
    
    MiscUtil.updateGuestCountsFromMap(pw.guestCounts);
    if (pw.productObj.guestsIsCount) {
      pw.quantity = pw.guestCounts.count;
    } else {
      pw.quantity = pw.guestCounts.count > 0 ? 1 : 0;
    }

    this.onChange(checked, 'checkbox');
  }

  onChange($event:Event, type:'input'|'checkbox') {
    let pw = this.pw;
    if(this.bas.envtest) console.log("onChange, ev: " + $event + ", pw: ", pw);

    // copyGuestCounts  | productObj.guestsIsCount



    // let priceRule = pw.priceRule || "PriceCount";
    // pw.amountDouble = pw.unitAmount * pw.quantity;
    this.bas.os.updatePwAmount(pw);

    this.updateAmount();
    this.change.emit({ field: "pw", value: pw });
  }

  updateAmount() {
    // let pw = this.pw;
    // let amount = pw.totalAmountDouble;
    // for (let acc of pw.accessoriesOptional) {
    //   amount += acc.amountDouble;
    // }
    // pw.calcAmount = amount;
  }

}
